$(window).on("resize", function (e) {
    if ($(".parallax ")[0]) {
        parallax();
    }
});

//HOME PLAY VIDEO ON CLICK
    $(".mob-home__play").on("click", function () {
    $(".video_interactive").addClass("active");
});
//HOME PLAY VIDEO ON CLICK END

//PARALLAX
function parallax() {
    var scene = $('#scene')[0];
    var parallaxInstance = new Parallax(scene);
    parallaxInstance.hoverOnly = true;

    if ($(window).width() > 1024) {

        parallaxInstance.scalar(10, 10);
        parallaxInstance.origin(0.5, 0.5);

    }
    else if ($(window).width() >= 768) {
        parallaxInstance.scalar(7, 7);
        parallaxInstance.origin(0.5, 0.6);
    }

    else {
        parallaxInstance.scalar(7, 7);
        parallaxInstance.origin(0.5, 0.8);
    }
}

if ($(".parallax ")[0]) {
    parallax();
}
